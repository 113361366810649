.label-file {
  background-color: #3498db;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  margin: 10px;
  padding: 6px 20px;
}

main.new-event-pages {
  background-color: #0c001e;
  color: white;
  min-height: 100vh;
  height: auto;
}

div.modal-background {
  color: white;
  margin: auto;
  width: 80%;
}

button.button-next {
  background-color: #4a10bd;
}

button.button-next:hover:focus:active {
  background-color: #2e0870;
}

button.button-back {
  color: #ac8af1;
  background-color: transparent;
  border-radius: 5px;
  border: #ac8af1 1px solid;
  width: 10em;
}

button.button-back:hover {
  background-color: #bba3e4;
  color: #ffffff;
}

.form-floating > label {
  color: #0c001e;
}

.slogan-new-event {
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 26px;
  width: max-content;
  padding: 5% 0 5% 0;
}

.col-slogan {
  padding-left: 30%;
}

@media screen and (max-width: 1024px) {
  .modal-background {
    padding: 3rem 0 !important;
  }
}

@media screen and (max-width: 768px) {
    .step-1-text{
        width: 100% !important;
    }

    .button-next{
        width: 50% !important;
    }
}
// ---------------setp 02------------------------------

button.button-lote {
  color: #ffffff;
  color: #ac8af1;
  background-color: transparent;
  border-radius: 5px;
  border: #ac8af1 1px solid;
  margin-top: 1em;
}

button.button-lote:hover {
  border: #ac8af1 1px solid;
  background-color: transparent;
  color: #bba3e4;
}

button.button-espace {
  color: #ffffff;
  background-color: #ac8af1;
  border-radius: 5px;
  border: none;
  margin-top: 1em;
}

button.button-espace:hover {
  background-color: #bba3e4;
}

button.button-cabine {
  color: #ffffff;
  color: #ac8af1;
  background-color: transparent;
  border-radius: 5px;
  border: #ac8af1 1px solid;
  margin-top: 1em;
}

button.button-cabine:hover {
  border: #ac8af1 1px solid;
  background-color: transparent;
  color: #bba3e4;
}

.new-event-pages input {
  color: #ffffff !important;
  background-color: transparent !important;
  border: #bba3e4 solid 1px !important;
  // height: 55px !important;
}

.new-event-pages .form-control > input {
  color: #ffffff !important;
  font-size: 15px;
}

textarea.description {
  color: #ffffff !important;
  background-color: transparent !important;
  border: #bba3e4 solid 1px !important;
}

.new-event-pages .form-floating > input {
  color: #ffffff !important;
  font-size: 15px;
}

// ---------------setp 03------------------------------

p.desc {
  color: #ffffff !important;
  font-size: 15px;
}

h6.subtitle {
  color: #bba3e4;
}

button.button-create {
  color: #ffffff;
  background-color: #ac8af1;
  border-radius: 5px;
  border: none;
  width: 20em;
  align-content: center;
  display: block;
  margin-top: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

button.button-create:hover {
  background-color: #bba3e4;
}

.new-event-pages .form-floating > label {
  color: #ffffff;
  background-color: transparent !important;
}

.new-event-pages .form-floating > select {
  color: #ffffff !important;
  background-color: transparent !important;
  border: #bba3e4 solid 1px !important;
  font-size: 15px;
}

.new-event-pages .form-floating > select > option {
  color: #ac8af1;
}
