main.new-event-success-page{
    width: 100vw;
    height: 100vh;
    background-color: #0C001F;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button{
        background-color: #7150a5;
    }

    button:hover{
        background-color: #391279;
    }
}