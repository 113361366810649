main.recover-password-page{
    width: 100vw;
    height: 100vh;
    background-color: #0C001F;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div.form-recover{
        width: 30%;
        height: fit-content;

        button.recover-password{
            border: none;
            background-color: #8C52FF;
        }
    }

    @media screen and (max-width: 1100px){
        div.form-recover{
            width: 40%;
        }
    }

    @media screen and (max-width: 900px){
        div.form-recover{
            width: 50%;
        }
    }

    @media screen and (max-width: 600px){
        div.form-recover{
            width: 60%;
        }
    }

    @media screen and (max-width: 450px){
        div.form-recover{
            width: 80%;
        }
    }
}