main.account-view {
  background-color: #0c001e;
  min-height: 100vh;
  width: 100vw;
  position: relative;

  div.welcome{
    .logout-area {
      position: absolute;
      width: 75%;
      margin-left: 12.5%;
      display: flex;
      justify-content: flex-end;
  
      button.logout:hover {
        background-color: #851c1c;
      }
    }

    h1{
      font-size: 2.5rem;
    }
  }

  div.title-forms {
    p.data-title {
      font-family: "Montserrat";
      font-weight: 300;
      font-size: 28px;
      color: #fff;
    }

    img.edit {
      width: 34.51px;
      height: 43px;
    }
  }
}

@media screen and (max-width: 650px) {
  .account-view > .welcome > h4 {
    padding-top: 4rem;
  }
}
