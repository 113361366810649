main.onboarding{
    background-color: #0C001E;
    height: 100%;
    width: 100%;
    overflow-x: hidden; 
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    div.title{
        width: 90%;
        display: flex;
        flex-direction: row;
        margin-top: 5em;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
        padding-top: 4em;
        

        h1.title{
            color: aliceblue;
            width: 45%;
            align-items: center;
            text-align: center;
            font-size: 3.8em;

        }

        ul.title{
            width: 47%;
            margin-left: 5%;
            align-items: center;

            img{
                width: 70%;
            }
        }
    }

    article.reports{
        width: 90%;
        height: 100vh;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
        padding-top: 7em ;

        h1.sub-title{
            color: aliceblue;
            font-weight: 500;
            width: 45%;
            align-items: center;
            text-align: center;
            margin-right: auto;
            margin-left: auto;
            font-size: 2.5em;
            margin-top: 4em;
        }

        h3.reports{
            margin-top: 3em;
            color: aliceblue;
            font-weight: 300;
            font-size: 1.9em;
        }

        div.reports-end{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        h5.reports{
            margin-top: 3em;
            color: aliceblue;
            font-weight: 300;
            width: 45%;
            font-size: 1.3em;
            text-align: right;
        }

        img.blur{
            opacity: 0.3;
            z-index: -1;
            margin-left: 55em;
            margin-top: -25em;
        }

    }

    article.app{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 90%;
        height: fit-content;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
        padding-top: 7em ;
        

        div.texts{

            color: aliceblue;
            margin-bottom: 10em;
            margin-top: 10em;
            text-align: center;
            justify-content: space-around;
            
            
            h4.marked-text{
                background-color: #9d74eaa4;
                border-radius: 9px;
                font-weight: 400;
                font-size: 1.1em;
                width: 50%;
                padding-left: 0.5%;
            }

            h3.app{
                font-weight: 500;
                font-size: 3.5em;
                font-style: italic;
            }

            h5.app{
                text-align: start;
                font-weight: 300;
                font-size: 1.6em;
            }
        }

        img.app{
            height: 30%;
            width: 30%;
        }
    }

}
