main.home-view {
  background-color: #0c001e;

  article.content {
    height: 90vh;

    h1 {
      font-size: 2.5rem;
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    }

    button.criar-evento {
      background-color: #8c52ff;
      -webkit-box-shadow: 0px 0px 200px 9px #ac8af1;
      -moz-box-shadow: 0px 0px 200px 9px #ac8af1;
      box-shadow: 0px 0px 200px 9px #ac8af1;
      transition: 0.5s;
    }

    button.criar-evento:hover {
      background-color: #9d6cff;
      scale: 1.1;
    }
  }

  article.transition-area {
    height: 80vh;
    background-image: linear-gradient(to bottom, #8c52ff, #ac8af1);
    background-size: cover;
    border-radius: 350px;
  }
}
