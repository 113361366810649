main.sign-in {
  width: 100vw;
  height: 100vh;
  background-color: #0c001f;
  font-family: "Poppins";

  div.background-auth {
    width: 80%;
    height: 80%;
    background-color: #eee;
    background-image: url("../../assets/images/login-business-big.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    box-shadow: 1px 1px 10px 3px rgb(0, 0, 0);

    form#form-login {
      width: 35%;
      margin-right: 5vw;

      h1 {
        font-size: 2rem;
      }

      #btn-signin {
        background-color: #0c001f;
      }

      #btn-signin:hover {
        background-color: #1a0045;
      }

      small a {
        text-decoration: none;
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  main.sign-in > div.background-auth {
    width: 90%;
  }
}

@media screen and (max-width: 1050px) {
  main.sign-in > div.background-auth {
    width: 95%;
  }
}

@media screen and (max-width: 992px) {
  main.sign-in > div.background-auth {
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
    background-image: none;
    justify-content: center !important;

    form#form-login {
      width: 70%;
    }
  }
}

@media screen and (max-width: 450px) {
  main.sign-in > div.background-auth {
    form#form-login {
      width: 80%;

      small{
        gap: 5px;
        flex-direction: column;
      }
    }
  }
}
