main.sign-up {
  width: 100vw;
  height: 100vh;
  background-color: #0c001f;
  font-family: "Poppins";

  div.background-auth {
    width: 80%;
    height: 85%;
    background-color: #eee;
    background-image: url("../../assets/images/signUp-business-big.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    box-shadow: 1px 1px 10px 3px rgb(0, 0, 0);
  }

  form#form-login {
    height: fit-content;
    width: 35%;
    margin-left: 5vw;
    
    h1 {
      font-size: 1.7rem;
    }
    
    #btn-signup {
      background-color: #0c001f;
    }

    #btn-signup:hover {
      background-color: #1a0045;
    }

    small a {
      text-decoration: none;
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width: 1250px) {
  main.sign-up > div.background-auth {
    width: 90%;
  }
}

@media screen and (max-width: 1050px) {
  main.sign-up > div.background-auth {
    width: 95%;
  }
}

@media screen and (max-width: 992px) {
  main.sign-up > div.background-auth {
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
    background-image: none;
    justify-content: center !important;

    form#form-login {
      width: 70%;
    }
  }
}

@media screen and (max-width: 450px) {
  main.sign-up > div.background-auth {
    form#form-login {
      width: 80%;

      small{
        gap: 5px;
        flex-direction: column;
      }
    }
  }
}
